//导包
import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
 
//使用路由组件
Vue.use(VueRouter);
Vue.use(ElementUI)
 
// 定义路由表
var routes =[
    {   //启动之后显示的页面
        name:"index",
        path:"/",
        component:()=> import('@/view/index'),
    },
    {
        name:"main",
        path:"/main",
        component:()=> import('@/view/index2'),
        children:[
        ]
    },
]
 
// 创建router实例
var router =new VueRouter({
    routes
})
 
// 导出router
export default router