import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as uni from './assets/js/web'  
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
document.addEventListener("UniAppJSBridgeReady", function() { 
	console.log("h5通信")
  uni.webView.postMessage({  
    data: {  
      action: "onReady",  
    },  
  }); 
    Vue.prototype.myUni = uni  //这样全局就能使用了
});

Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api'  //关键代码
Vue.config.productionTip = false
 
Vue.use(ElementUI)
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')